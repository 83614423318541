import { lazy } from 'react'
import DashboardLayout from '../components/layouts/dashboard-layout'
import { defaultRoutes } from './default-routes'

const AuthGuard = lazy(() => import('../components/guards/auth-guard'))

const Overview = lazy(() => import('../features/overview/admin'))
const RegisterPerson = lazy(() => import('../features/persons/register'))
const CreateEvent = lazy(() => import('../features/events/create'))
const Events = lazy(() => import('../features/events/overview'))
const Event = lazy(() => import('../features/events/detail'))
const PersonsOverview = lazy(() => import('../features/persons/overview'))
const PersonOverview = lazy(() => import('../features/persons/detail'))

export const adminRoutes = [
  ...defaultRoutes,
  {
    path: '*',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Overview />
      },
      {
        path: 'register',
        element: <RegisterPerson />
      },
      {
        path: 'persons/:id',
        element: <PersonOverview />
      },
      {
        path: 'persons',
        element: <PersonsOverview />
      },
      { path: 'events/:id', element: <Event /> },
      { path: 'events', element: <Events /> },
      { path: 'create-event', element: <CreateEvent /> },
      { path: '*', element: <Overview /> }
    ]
  }
]
