import React, { CSSProperties, FC } from 'react'

const Logo: FC<{ style?: CSSProperties }> = ({ style }) => {
  return (
    <div style={style}>
      <img alt='logo' height='100%' src='/static/logos/logo.png' />
    </div>
  )
}

export default Logo
