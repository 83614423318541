import './i18n/config'
import { useIsFetching } from '@tanstack/react-query'
import { Suspense } from 'react'
import { useRoutes } from 'react-router-dom'
import { LoadingSpinner } from './components/loading-spinner'
import { adminRoutes } from './routes/admin-routes'
import { Footer } from './components/footer'
import LoadingScreen from './features/loading-screen'
import { Box, Button } from '@mui/material'
import useAuth from './hooks/useAuth'
import { userRoutes } from './routes/user-routes'
import { receptionistRoutes } from './routes/receptionist-routes'

const getRoutesFor = (user: User | null) => {
  if (!user) return userRoutes

  switch (user.role) {
    case 'ADMIN':
      return adminRoutes
    case 'RECEPTIONIST':
      return receptionistRoutes
  }
}

function App() {
  const isFetching = useIsFetching()
  const { isLoading, user, setUser } = useAuth()
  const content = useRoutes(getRoutesFor(user))

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Box sx={{ marginBottom: 5, marginTop: 5 }}>{content}</Box>
      <Footer />
      <LoadingSpinner isVisible={isLoading || isFetching > 0} />
    </Suspense>
  )
}

export default App
