import { lazy } from 'react'
import { defaultRoutes } from './default-routes'
import { Navigate } from 'react-router-dom'

const RegisterPerson = lazy(() => import('../features/persons/register'))

export const userRoutes = [
  ...defaultRoutes,
  {
    path: '/',
    element: <RegisterPerson />
  },
  { path: '*', element: <Navigate to='/' /> }
]
