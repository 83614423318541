import { lazy } from 'react'
import DashboardLayout from '../components/layouts/dashboard-layout'
import { defaultRoutes } from './default-routes'

const AuthGuard = lazy(() => import('../components/guards/auth-guard'))

const Overview = lazy(() => import('../features/overview/receptionist'))
const SearchPersons = lazy(() => import('../features/persons/overview'))

export const receptionistRoutes = [
  ...defaultRoutes,
  {
    path: '*',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Overview />
      },
      {
        path: 'search',
        element: <SearchPersons />
      },
      { path: '*', element: <Overview /> }
    ]
  }
]
