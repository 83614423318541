import { QueryClient } from '@tanstack/react-query'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: 1,
      staleTime: 5 * 1000
    }
  }
  // queryCache: new QueryCache({
  //   onError: async (error: { message: string }, query) => {
  //     // How to get status code fo error
  //     toast.error(`Something went wrong: ${error.message}`)
  //   }
  // })
})
