import { createContext, useEffect, useReducer } from 'react'
import type { FC, ReactNode } from 'react'
import {
  deleteLocalStorage,
  getToken,
  setTokens,
  setUsername
} from '../services/local-storage-service'
import { axiosInstance } from '../lib/axios'

interface State {
  isInitialized: boolean
  isAuthenticated: boolean
  user: User | null
  username: string | null
  isLoading: boolean
}

interface AuthContextValue extends State {
  setUser: (user: User) => Promise<void>
  login: (data: LoginPayload) => Promise<void>
  logout: () => Promise<void>
  register: (data: RegisterPayload) => Promise<void>
  verifyCode: (data: VerifyCodePayload) => Promise<void>
  resendCode: (username: string) => Promise<void>
  passwordRecovery: (username: string) => Promise<void>
  passwordReset: (username: string, code: string, newPassword: string) => Promise<void>
  changePassword: (oldPassword: string, newPassword: string) => Promise<void>
}

interface AuthProviderProps {
  children: ReactNode
}

type InitializeAction = {
  type: 'INITIALIZE'
  payload: {
    isAuthenticated: boolean
    user: User | null
    username: string | null
  }
}

type LoginAction = {
  type: 'LOGIN'
  payload: {
    user: User | null
    username: string | null
  }
}

type LogoutAction = {
  type: 'LOGOUT'
}

type RegisterAction = {
  type: 'REGISTER'
  payload: {
    user: User
  }
}

type VerifyCodeAction = {
  type: 'VERIFY_CODE'
}

type ResendCodeAction = {
  type: 'RESEND_CODE'
}

type PasswordRecoveryAction = {
  type: 'PASSWORD_RECOVERY'
}

type PasswordResetAction = {
  type: 'PASSWORD_RESET'
}

type ChangePasswordAction = {
  type: 'CHANGE_PASSWORD'
}

type StartLoadingAction = {
  type: 'START_LOADING'
}

type StopLoadingAction = {
  type: 'STOP_LOADING'
}

type Action =
  | InitializeAction
  | LoginAction
  | LogoutAction
  | RegisterAction
  | VerifyCodeAction
  | ResendCodeAction
  | PasswordRecoveryAction
  | PasswordResetAction
  | ChangePasswordAction
  | StartLoadingAction
  | StopLoadingAction

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  username: null,
  isLoading: false
}

const handlers: Record<string, (state: State, action: any) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user, username } = action.payload

    return {
      ...state,
      isLoading: false,
      isAuthenticated,
      isInitialized: true,
      user,
      username
    }
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user, username } = action.payload

    return {
      ...state,
      isLoading: false,
      isAuthenticated: true,
      user,
      username
    }
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null,
    username: null,
    isLoading: false
  }),
  REGISTER: (state: State, action: RegisterAction): State => {
    const { user } = action.payload

    return {
      ...state,
      isAuthenticated: false,
      user,
      isLoading: false
    }
  },
  VERIFY_CODE: (state: State): State => ({ ...state, isLoading: false }),
  RESEND_CODE: (state: State): State => ({ ...state, isLoading: false }),
  PASSWORD_RECOVERY: (state: State): State => ({ ...state, isLoading: false }),
  PASSWORD_RESET: (state: State): State => ({ ...state, isLoading: false }),
  CHANGE_PASSWORD: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    isLoading: false
  }),
  START_LOADING: (state: State): State => ({ ...state, isLoading: true }),
  STOP_LOADING: (state: State): State => ({ ...state, isLoading: false })
}

const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  setUser: () => Promise.resolve(),
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  verifyCode: () => Promise.resolve(),
  resendCode: () => Promise.resolve(),
  passwordRecovery: () => Promise.resolve(),
  passwordReset: () => Promise.resolve(),
  changePassword: () => Promise.resolve()
})

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props
  const [state, dispatch] = useReducer(reducer, initialState)

  const setUser = async (newUser: User) => {
    dispatch({
      type: 'INITIALIZE',
      payload: {
        user: newUser,
        isAuthenticated: state.isAuthenticated,
        username: state.username
      }
    })
  }

  const getMe = async () => {
    dispatch({ type: 'START_LOADING' })
    axiosInstance
      .get<MeResponse>('auth/me')

      .then((response) => {
        const { user, username } = response.data
        setUsername(username)
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user: user,
            username
          }
        })
      })
      .catch((err) => {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
            username: null
          }
        })
      })
  }

  useEffect(() => {
    const token = getToken('AccessToken')
    if (token) {
      getMe()
    }
  }, [])

  const login = async (data: LoginPayload): Promise<void> => {
    dispatch({ type: 'START_LOADING' })

    return axiosInstance
      .post<LoginResponse>('auth/signIn', data)
      .then((response) => {
        const { AccessToken, RefreshToken } = response.data
        setTokens(AccessToken, RefreshToken)
        getMe()
      })
      .finally(() => {
        dispatch({ type: 'STOP_LOADING' })
      })
  }

  const logout = async (): Promise<void> => {
    dispatch({ type: 'START_LOADING' })
    await axiosInstance
      .post('auth/signOut')
      .then(() => {
        deleteLocalStorage()
        dispatch({ type: 'LOGOUT' })
      })
      .finally(() => {
        dispatch({ type: 'STOP_LOADING' })
      })
  }

  const register = async (data: RegisterPayload): Promise<void> => {
    dispatch({ type: 'START_LOADING' })
    return axiosInstance
      .post<RegisterResponse>('/auth/signUp', data)
      .then((response) => {
        const { user } = response.data

        dispatch({
          type: 'REGISTER',
          payload: {
            user
          }
        })
      })
      .finally(() => {
        dispatch({ type: 'STOP_LOADING' })
      })
  }

  const verifyCode = async (data: VerifyCodePayload): Promise<void> => {
    dispatch({ type: 'START_LOADING' })
    return axiosInstance
      .post<{ accessToken: string; user: User }>('/auth/confirmSignUp', data)
      .then(() =>
        // TODO
        // const { accessToken } = response.data

        // window.localStorage.setItem('accessToken', accessToken.replace('Bearer ', ''))
        dispatch({
          type: 'VERIFY_CODE'
        })
      )
      .finally(() => dispatch({ type: 'STOP_LOADING' }))
  }

  const resendCode = async (email: string): Promise<void> => {
    dispatch({ type: 'START_LOADING' })
    return axiosInstance
      .post<{ accessToken: string; user: User }>('/auth/resendCode', {
        email
      })
      .then((response) => {
        const { accessToken } = response.data
        // setTokens()
        // window.localStorage.setItem('accessToken', accessToken.replace('Bearer ', ''))
        dispatch({
          type: 'RESEND_CODE'
        })
      })
      .finally(() => {
        dispatch({ type: 'STOP_LOADING' })
      })
  }

  const passwordRecovery = async (email: string): Promise<void> => {
    dispatch({ type: 'START_LOADING' })
    return axiosInstance
      .post<{ accessToken: string; user: User }>('/auth/forgotPassword', {
        email
      })
      .then(() =>
        dispatch({
          type: 'PASSWORD_RECOVERY'
        })
      )
      .finally(() => {
        dispatch({ type: 'STOP_LOADING' })
      })
  }

  const passwordReset = async (email: string, code: string, password: string): Promise<void> => {
    dispatch({ type: 'START_LOADING' })
    return axiosInstance
      .post<{ accessToken: string; user: User }>('/auth/confirmNewPassword', {
        email,
        password,
        code
      })
      .then(() =>
        dispatch({
          type: 'PASSWORD_RESET'
        })
      )
      .finally(() => {
        dispatch({ type: 'STOP_LOADING' })
      })
  }

  const changePassword = async (oldPassword: string, newPassword: string): Promise<void> => {
    dispatch({ type: 'START_LOADING' })
    return axiosInstance
      .put<{ accessToken: string; user: User }>('/auth/changePassword', {
        previousPassword: oldPassword,
        proposedPassword: newPassword
      })
      .then((response) => {
        if (response.status < 300) {
          deleteLocalStorage()
          dispatch({
            type: 'CHANGE_PASSWORD'
          })
        }
      })
      .finally(() => {
        dispatch({ type: 'STOP_LOADING' })
      })
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        setUser,
        login,
        logout,
        register,
        verifyCode,
        resendCode,
        passwordRecovery,
        passwordReset,
        changePassword
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext
