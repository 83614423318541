import { Event, Home, Person } from '@mui/icons-material'

export const adminSections = [
  {
    title: 'Home',
    items: [{ title: 'Home', path: '/', icon: <Home fontSize='small' /> }]
  },
  {
    title: 'Persons',
    items: [
      {
        title: 'Overview',
        path: '/persons',
        icon: <Home fontSize='small' />
      },
      {
        title: 'Add new',
        path: '/register',
        icon: <Person fontSize='small' />
      }
    ]
  },
  {
    title: 'Events',
    items: [
      {
        title: 'Overview',
        path: '/events',
        icon: <Home fontSize='small' />
      },
      {
        title: 'Add new',
        path: '/create-event',
        icon: <Event fontSize='small' />
      }
    ]
  }
]
