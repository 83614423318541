import { Search, Store } from '@mui/icons-material'

export const receptionistSections = [
  {
    title: 'Persons',
    items: [
      {
        title: 'Cash Register',
        path: '/',
        icon: <Store fontSize='small' />
      },
      {
        title: 'Search',
        path: '/search',
        icon: <Search fontSize='small' />
      }
    ]
  }
]
