import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import de from './de'
import en from './en'
import es from './es'

i18next.use(initReactI18next).use(LanguageDetector).init({
  // lng: 'en', // if you're using a language detector, do not define the lng option
  fallbackLng: 'en',
  debug: true,
  resources: {
    en,
    de,
    es
  }
})
